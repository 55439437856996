<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Product</h1>
            </div>
        </div>

        <div class="row ">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div>
                            <button type="button" class="btn btn-primary btn-with-icon" @click="dateUpload=null,FileUpload=null;showUpload=true"><i class="ion-android-upload"></i>Inventory Upload</button>
                        </div>

                        <div class="mt-3">
                          
                            <label class="checkbox-inline custom-checkbox nowrap me-3">
                                <input type="checkbox" @click="fileFilterActive=!fileFilterActive" :checked="fileFilterActive?true:null">
                                <span>Activated</span>
                            </label>
                            <label class="checkbox-inline custom-checkbox nowrap">
                                <input type="checkbox" @click="fileFilterDeactive=!fileFilterDeactive" :checked="fileFilterDeactive?true:null">
                                <span>Deactivated</span>
                            </label>
                        </div>

                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable">
                                <thead>
                                    <tr>
                                        <th id="columnName">Name</th>
                                        <th>File Date</th>
                                        <th>Created</th>
                                        <th>Owner</th>
                                        <th>Download</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in dataFiltered" :key="item.id">
                                        <td>{{item.FileName}}</td>
                                        <td>{{formatDate(item.SelectedDate,true)}}</td>
                                        <td>{{formatDate(item.UploadedDate)}}</td>
                                        <td>{{item.CreatedBy}}</td>
                                        <td>{{item.Downloaded}}</td>
                                        <td>
                                            <button type="button" class="btn btn-icon"
                                                    @click="changeStatus(item.Id)"
                                                    :class="{'btn-success':!item.IsDisable,'btn-danger':item.IsDisable}">
                                                <i :class="{'ion-android-checkmark-circle':!item.IsDisable,'ion-android-cancel':item.IsDisable}"></i>
                                            </button>
                                            <button type="button" class="btn btn-icon btn-primary"
                                                    @click="downloadFile(item.Id)">
                                                <i class="ion-arrow-down-a"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MDBModal v-model="showUpload" size="sm">
            <MDBModalHeader>
                <MDBModalTitle> Upload Inventory </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                Date of Inventory's file:
                <Datepicker v-model="dateUpload" dark class="mb-3" autoApply>
                    <template #calendar-header="{ index, day }">
                        <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">
                            {{ day }}
                        </div>
                    </template>
                </Datepicker>
                <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-document"></i>Select a file</button>
                <div class="mt-3" id="uploadFileInfo">

                </div>
            </MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-primary" @click="UploadInventory"><i class="ion-android-upload"></i> Upload</button>
                <button type="button" class="btn btn-danger" @click="showUpload=false"><i class="ion-close"></i> Cancel</button>
            </MDBModalFooter>
        </MDBModal>

       
    </div>
</template>
<style>

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'

    //========== Datetime picker
    //https://vue3datepicker.com/installation/#global
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'

    import {
        //MDBBtn,
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
    } from 'mdb-vue-ui-kit';

    import c from '@/mylib/common'
    import { mapState,mapActions } from 'vuex'
    export default {
        name: `Inventory`,
        data: function () {
            return {
                dateUpload:null,
                LayoutModel: {},
                data: [],
                dataFiltered: [],
                dataTable:null,
                showUpload: false,
                FileUpload: null,
                fileFilterDeactive: true,
                fileFilterActive: true,
            }
        },
        
        components: {
            Datepicker,
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',                
                'call': 'api/call',
                'callDownload': 'api/callDownload'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();
                
            },
            formatDate(dstr,notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatNumber(n) {                
                return c.formatNumber(n);
            },
            async getAll() {
                this.loading(true);
                
                const rs = await this.call({ Action: 'Inventory/List' })
                
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {                    
                    this.data = rs.Data;
                    this.doTableFilter();
                }
            },
            async changeStatus(id) {
                this.loading(true);
                const rs = await this.call({ Action: 'Inventory/Toggle', Object: { FileID: id} })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    //if (this.dataTable != null) {
                    //    this.dataTable.destroy();
                    //}
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].Id == id) {
                            this.data[i].IsDisable = !this.data[i].IsDisable;
                            this.doTableFilter();
                            this.showToast({
                                isShow: true, title: '', content: `${this.data[i].FileName} is <span style="font-weight: bold;` + (this.data[i].IsDisable ? 'color: red;">Disabled' :'color: blue;">Enabled')+"</span>", type: 'success' });
                            break;
                        }
                    }
                    
                    //setTimeout(this.doTable, 200);
                }
            },
            async downloadFile(id) {
                this.loading(true);
                //get filename
                let fileName = '';
                let fileIndex = 0;
                for (let fileIndex = 0; fileIndex < this.data.length; fileIndex++) {
                    if (this.data[fileIndex].Id == id) {
                        fileName = this.data[fileIndex].FileName;
                        break;
                    }
                }
                if (fileName == '' || fileName == undefined) {
                    this.showToast({
                        isShow: true, title: '', content: `File not found!`, type: 'error'
                    });
                    return;
                }
                const rs = await this.call({ Action: 'Inventory/DownloadFile', Object: { FileID: id,fileName: fileName} })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    c.SaveFile(rs.Data, "application/octet-stream", fileName);
                    this.data[fileIndex].Downloaded++;
                }
            },
            doTableFilter() {
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                this.dataFiltered = [];
                const _this = this;
                for (let i = 0; i < this.data.length; i++) {
                    const item = _.clone(this.data[i]);
                    
                    //do filter
                    if (_this.fileFilterActive && !item.IsDisable
                        || _this.fileFilterDeactive && item.IsDisable) {
                        _this.dataFiltered.push(item);
                    }
                }
                setTimeout(this.doTable, 200);
            },
            doTable() {
                const _this = this;
                
                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                    "order": [[2, "desc"]]
                    
                });
                this.loading(false);
            },
            SelectFileUpload() {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                el.accept = ".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
                //el.accept = "image/*";
                //el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)

                    // add first image, if available
                    if (el.files.length) {
                        console.log(el.files[0]);
                        _this.FileUpload = el.files[0];
                        document.getElementById('uploadFileInfo').innerHTML = _this.FileUpload.name;
                    }


                    //// test some async handling
                    //new Promise(function (resolve) {
                    //    setTimeout(function () { console.log(el.files); resolve(); }, 1000);
                    //})
                    //    .then(function () {
                    //        // clear / free reference
                    //        el = window._protected_reference = undefined;
                    //    });

                });

                el.click();
            },
            async UploadInventory() {                
                if (this.dateUpload == null) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a date", type: 'error' });
                    return;
                }
                if (this.FileUpload == null) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a file to upload", type: 'error' });
                    return;
                }
                
                this.loading(true);

                const rs = await this.call({ Action: 'Inventory/Upload', Object: { SelectedDate: this.dateUpload }, Files: this.FileUpload })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    
                } else {
                    this.getAll();
                    this.showUpload = false;
                }
            }
        },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                
                if (newVal) {
                    this.start();
                }
            },
            fileFilterActive() {
                this.doTableFilter();
            },
            fileFilterDeactive() {
                this.doTableFilter();
            }
        }
    };
</script>
